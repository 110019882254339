import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../../../context/ConfigContext";
import { useDeviceSize } from "../../../context/DeviceSizeContext";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import CasinoGameItem from "./CasinoGameItem";

function HorizontalScrollGames({ game = null, title, games, category = null }) {
  const DeviceSizeProvider = useDeviceSize();
  const scrollContainerRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [itemWidth, setItemWidth] = useState(
    DeviceSizeProvider.deviceType === "sm" ? 145 : 193
  );
  let scrollTimeout = null;

  const scrollLeft = (left = false) => {
    if (scrollContainerRef.current) {
      const measuredWidth = parseInt(
        scrollContainerRef.current.getBoundingClientRect().width
      );
      scrollContainerRef.current.scrollBy({
        left: left ? -measuredWidth : measuredWidth,
        behavior: "smooth",
      });
    }
  };

  const handleResize = () => {
    const containerWidth = scrollContainerRef.current?.offsetWidth;
    if (containerWidth) {
      const minItemWidth = DeviceSizeProvider.deviceType === "sm" ? 145 : 193;

      const columns = Math.floor(containerWidth / minItemWidth);

      setItemWidth(Math.max(minItemWidth, containerWidth / columns));
    }

    handleScroll();
  };

  const handleScroll = () => {
    const scrollElement = scrollContainerRef.current;
    if (!scrollElement) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollElement;
    setIsAtStart(scrollLeft === 0);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 10);

    if (scrollTimeout) clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      const items = Array.from(scrollElement.children);
      let closestItem = null;
      let closestOffset = Infinity;

      items.forEach((item) => {
        const offset = Math.abs(item.offsetLeft - scrollLeft);
        if (offset < closestOffset) {
          closestOffset = offset;
          closestItem = item;
        }
      });

      if (closestItem) {
        smoothScrollTo(scrollElement, closestItem.offsetLeft, 200);
      }
    }, 500);
  };

  const smoothScrollTo = (element, targetLeft, duration) => {
    const startLeft = element.scrollLeft;
    const distance = targetLeft - startLeft;
    const startTime = performance.now();

    const animateScroll = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easeInOutQuad =
        progress < 0.5
          ? 2 * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 2) / 2;

      element.scrollLeft = startLeft + distance * easeInOutQuad;

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    const scrollElement = scrollContainerRef.current;
    if (!scrollElement) return;

    scrollElement.addEventListener("scroll", handleScroll);
    scrollElement.addEventListener("touchend", handleScroll);
    scrollElement.addEventListener("mouseup", handleScroll);
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
      scrollElement.removeEventListener("touchend", handleScroll);
      scrollElement.removeEventListener("mouseup", handleScroll);
      if (scrollTimeout) clearTimeout(scrollTimeout);
    };
    // eslint-disable-next-line
  }, [scrollContainerRef.current]);

  useEffect(() => {
    if (games.length > 0) {
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    // eslint-disable-next-line
  }, [itemWidth, games, DeviceSizeProvider.deviceType]);

  const ConfigProvider = useConfig();

  return games.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="mt-4 flex h-8 items-center mb-4 sm:mt-6">
        <div className="flex items-center text-base font-semibold">{title}</div>
        {game ? (
          <Link
            to={`/casino/recommend${game ? `/${game}` : ""}`}
            className="bg-layout-bg hover:bg-layout-border ml-auto h-8 px-2 rounded-lg flex items-center"
          >
            <span className="">{ConfigProvider.getTranslation("All")}</span>
            <ChevronRightIcon className="ml-4 w-4 h-4 fill-current" />
          </Link>
        ) : category ? (
          <>
            <Link
              to={`casino/category/show/${encodeURIComponent(
                title
              )}/${category}`}
              className="bg-layout-bg hover:bg-layout-border ml-auto h-8 px-2 rounded flex items-center"
            >
              <span className="">{ConfigProvider.getTranslation("All")}</span>
              <ChevronRightIcon className="ml-4 w-4 h-4 fill-current" />
            </Link>
          </>
        ) : (
          <div className="ml-auto">&nbsp;</div>
        )}
        <div className="ml-2 flex gap-x-1">
          <button
            className="bg-layout-bg hover:bg-layout-border w-8 h-8 rounded    flex items-center"
            type="button"
            onClick={() => {
              scrollLeft(true);
            }}
            disabled={isAtStart}
          >
            <ChevronLeftIcon className="w-4 h-4 fill-current mx-auto" />
          </button>
          <button
            className="bg-layout-bg hover:bg-layout-border w-8 h-8 rounded flex items-center"
            type="button"
            onClick={() => {
              scrollLeft();
            }}
            disabled={isAtEnd}
          >
            <ChevronRightIcon className="w-4 h-4 fill-current mx-auto" />
          </button>
        </div>
      </div>
      <hr className="mt-3 mb-4 border-page-border" />
      <div
        ref={scrollContainerRef}
        className="smooth-scroll smooth-list snap-x relative snap-mandatory grid  grid-flow-col overflow-x-scroll overflow-y-hidden scroll-smooth noscrollbar text-center -mx-2"
      >
        {games.map((item, index) => (
          <div
            key={index}
            className="aspect-[16/9] px-1"
            style={{ width: `${itemWidth}px` }}
          >
            <CasinoGameItem item={item} />
          </div>
        ))}
      </div>
    </>
  );
}

export default HorizontalScrollGames;
