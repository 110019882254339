import { Fragment, useState } from "react";
import { Transition, Listbox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import Flag from "../../Flag";
import { useConfig } from "../../../../context/ConfigContext";
import Requests from "../../../../helpers/Requests";
import { usePrefences } from "../../../../context/PrefencesContext";
import { classNames } from "../../../../helpers/Helpers";

function LanguageDropdown({ showTitle = true }) {
  const ConfigProvider = useConfig();
  const conf = ConfigProvider.config;
  const items = Object.keys(conf.languages).map((key) => ({
    name: conf.languages[key],
    id: key,
  }));
  const [selected] = useState(items.filter((x) => x.id === conf.lan)[0]);

  const changeLanguage = async (item) => {
    Requests.postData("/user/setCulture", { lan: item.id })
      .then(() => {
        sessionStorage.removeItem("translations");
        window.location.reload();
      })
      .catch(() => {
        sessionStorage.removeItem("translations");
        window.location.reload();
      });
  };

  return (
    <Listbox value={selected} onChange={changeLanguage}>
      {({ open }) => (
        <>
          {showTitle && (
            <Listbox.Label className="block text-sm font-medium leading-6 mb-2">
              {ConfigProvider.getTranslation("Language")}
            </Listbox.Label>
          )}
          <div className="relative ">
            <Listbox.Button className="relative w-full rounded-md border border-page-text/15 bg-layout-bg px-3 py-2 text-sm text-page-text ring-offset-background placeholder:text-page-text focus:outline-none focus:ring focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
              <span className="flex items-center">
                <Flag
                  id={ConfigProvider.config.flagsOfLanguages[selected.id]}
                  svg
                />
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 " aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-layout-bg text-page-text border border-color-2 py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                {items.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    className={classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      item.id === selected.id ? "bg-background text-white" : ""
                    )}
                    value={item}
                  >
                    <div className="flex items-center">
                      <Flag
                        id={ConfigProvider.config.flagsOfLanguages[item.id]}
                      />
                      <span className=" ml-3 block truncate">{item.name}</span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
function TimezoneDropdown({ showTitle = true }) {
  const ConfigProvider = useConfig();
  const { prefences, savePrefences } = usePrefences();
  const items = Object.keys(ConfigProvider.config.timezones).map((item) => ({
    name: item,
  }));
  const [selected, setSelected] = useState(
    items.filter((x) => x.name === prefences.timezone)[0]
  );
  const updateTimezone = (item) => {
    savePrefences("timezone", item.name);
    setSelected(item);
  };
  return (
    <Listbox value={selected} onChange={updateTimezone}>
      {({ open }) => (
        <>
          {showTitle && (
            <Listbox.Label className="block text-sm font-medium leading-6 mb-2">
              {ConfigProvider.getTranslation("TimeZones")}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button className="relative w-full rounded-md border border-page-text/15 bg-layout-bg px-3 py-2 text-sm text-page-text ring-offset-background placeholder:text-page-text focus:outline-none focus:ring focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-layout-bg text-page-text border border-color-2 py-1 text-base shadow-lg focus:outline-none sm:text-sm">
                {items.map((item, index) => (
                  <Listbox.Option
                    key={index}
                    className={classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      item.name === selected.name
                        ? "bg-background text-white"
                        : ""
                    )}
                    value={item}
                  >
                    <div className="flex items-center">
                      <span className="ml-3 block truncate">{item.name}</span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
export { LanguageDropdown, TimezoneDropdown };
