import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowRightCircleIcon,
  KeyIcon,
  UserIcon,
  AtSymbolIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import CountryDropdown from "../components/Common/FormElements/CountryDropdown";
import CurrencyDropdown from "../components/Common/FormElements/CurrencyDropdown";
import Requests from "../helpers/Requests";
import { classNames } from "../helpers/Helpers";
import { useFavorites } from "../context/FavoritesContext";
import Turnstile, { useTurnstile } from "react-turnstile";

function Register() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  const turnstile = useTurnstile();
  useEffect(() => {
    if (ConfigProvider.config.user || !ConfigProvider.config.allowRegister) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const newRandomUname = () => {
    const letters = "ABCDEFGHJKLMNPRSTUVYZ";
    const numbers = "1234567890";
    let send = letters[Math.floor(Math.random() * letters.length)];

    for (let i = 0; i < 9; i++) {
      send += numbers[Math.floor(Math.random() * numbers.length)];
    }

    return send;
  };
  const newRandomPassword = () => {
    const numbers = "1234567890";
    let send = "";
    for (let i = 0; i < 4; i++) {
      send += numbers[Math.floor(Math.random() * numbers.length)];
    }

    return send;
  };
  const [formData, setFormData] = useState({
    uname: newRandomUname(),
    password: newRandomPassword(),
    country: "",
    currency: "",
    email: "",
    token: "",
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [messages, setMessages] = useState([]);
  const FavoritesProvider = useFavorites();
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];
      if (formData.country.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Country"));
      }
      if (formData.currency.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Currency"));
      }
      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        window.scrollTo(0, 0);
        setLoading(false);
      } else {
        let postData = { ...formData };
        if (postData.email.length === 0) {
          postData.email = null;
        }
        postData.btag = FavoritesProvider.btag;
        Requests.postData("/user/oneClickRegister", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              window.scrollTo(0, 0);
              turnstile && turnstile.reset();
            } else {
              setMessages([
                {
                  type: "success",
                  text: ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              if (res.message === "") {
                setTimeout(() => {
                  window.location.href = "/";
                }, 2000);
              } else {
                setTimeout(() => {
                  setShowVerify(true);
                }, 2000);
                turnstile && turnstile.reset();
              }
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            turnstile && turnstile.reset();
          });
      }
    }
  };
  const tabs = [
    {
      name: ConfigProvider.getTranslation("Register.MiniInfoForm"),
      href: "/register",
      current: true,
    },
    {
      name: ConfigProvider.getTranslation("Register.FullInfoForm"),
      href: "/registerFull",
      current: false,
    },
  ];

  const [showPassword, setShowPassword] = useState(true);
  const [showVerify, setShowVerify] = useState(false);

  useEffect(() => {
    getBtag();
    // eslint-disable-next-line
  }, []);
  const [btag, setBtag] = useState("");
  const getBtag = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/user/getBtag", {});
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type === "success") {
            setBtag(res.data);
            if (res.data === "" && FavoritesProvider.btag !== "") {
              setBtag(FavoritesProvider.btag);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3 z-10">
      <div className="flex flex-col w-full max-w-lg">
        <div className="flex items-center justify-center gap-4 text-xl from-home-from-5 to-home-to-5 bg-gradient-to-t py-2 w-full text-center rounded text-white/90 font-bold">
          <UserPlusIcon className="w-6 h-6 mr-2" />
          {ConfigProvider.getTranslation("Register")}
        </div>
        {!showVerify ? (
          <div className="mt-3 md:mt-10">
            <div className="block">
              <nav
                className="isolate flex divide-x divide-gray-700 rounded-lg shadow"
                aria-label="Tabs"
              >
                {tabs.map((tab, tabIdx) => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={classNames(
                      tab.current ? "bg-home-from-2" : "bg-home-to-2",
                      tabIdx === 0 ? "rounded-l-lg" : "",
                      tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10 border-home-to-2"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.current
                          ? "border-home-to-2 brightness-50 border-b-2"
                          : "",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </Link>
                ))}
              </nav>
            </div>
            <form className="flex-none w-full" onSubmit={updateForm}>
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>

              <div className="flex flex-col mb-6">
                <label
                  htmlFor="uname"
                  className="mb-1 text-xs sm:text-sm tracking-wid"
                >
                  {ConfigProvider.getTranslation("Username")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                    <UserIcon className="w-6 h-6 text-page-text" />
                  </div>

                  <input
                    id="uname"
                    name="uname"
                    type="text"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("Username")}
                    value={formData.uname}
                    onChange={handleChange}
                    required
                    maxLength={15}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="password"
                  className="mb-1 text-xs sm:text-sm tracking-wide"
                >
                  {ConfigProvider.getTranslation("Password")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                    <KeyIcon className="w-6 h-6 text-page-text" />
                  </div>

                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder="*******"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    maxLength={15}
                  />
                  <div
                    className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <EyeIcon className="w-4 h-4 text-page-text" />
                    ) : (
                      <EyeSlashIcon className="w-4 h-4 text-page-text" />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <div className="relative">
                  <CountryDropdown
                    onUpdate={(value) => {
                      setFormData({ ...formData, country: value });
                    }}
                    name={
                      <>
                        {ConfigProvider.getTranslation("Country")}:
                        <span className="text-red-500">*</span>
                      </>
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col mb-6">
                <div className="relative">
                  <CurrencyDropdown
                    onUpdate={(value) => {
                      setFormData({ ...formData, currency: value });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6">
                <label
                  htmlFor="email"
                  className="mb-1 text-xs sm:text-sm tracking-wide"
                >
                  {ConfigProvider.getTranslation("E-mail")}:
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                    <AtSymbolIcon className="w-6 h-6 text-page-text" />
                  </div>

                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("E-mail")}
                    value={formData.email}
                    onChange={handleChange}
                    maxLength={255}
                  />
                </div>
              </div>
              {btag.length > 0 && (
                <div className="flex flex-col mb-6">
                  <label
                    htmlFor="btag"
                    className="mb-1 text-xs sm:text-sm tracking-wide"
                  >
                    {ConfigProvider.getTranslation("Btag")}:
                  </label>
                  <div className="relative">
                    <input
                      id="btag"
                      name="text"
                      type="btag"
                      className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-4 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                      value={btag}
                      readOnly
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col mb-6">
                <div className="relative flex px-1">
                  <div className="flex items-center">
                    <input
                      id="ConfirmTermsAndConditions"
                      name="ConfirmTermsAndConditions"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      required
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="ConfirmTermsAndConditions"
                      className="font-medium"
                    >
                      <span className="text-red-500">*</span>
                      {` ${ConfigProvider.getTranslation("Confirm")} `}
                      <Link
                        to="/cms/terms?print=1"
                        target="_blank"
                        className="text-indigo-600 hover:text-indigo-500 hover:underline"
                      >
                        {ConfigProvider.getTranslation("TermsAndConditions")}
                      </Link>
                      {` & `}
                      <Link
                        to="/cms/rga?print=1"
                        target="_blank"
                        className="text-indigo-600 hover:text-indigo-500 hover:underline"
                      >
                        {ConfigProvider.getTranslation("RGAAndGT&C")}
                      </Link>
                    </label>
                  </div>
                </div>
              </div>
              {ConfigProvider.config.hostnames.captcha ? (
                <div className="w-full flex items-center justify-center mb-6">
                  <Turnstile
                    sitekey={ConfigProvider.config.hostnames.captchaKey}
                    onVerify={(token) => {
                      setFormData((prev) => {
                        return { ...prev, token: token };
                      });
                    }}
                    theme="auto"
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="flex flex-col">
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-1 text-page-bg font-bold"
                  disabled={loading}
                >
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Register")}
                  </span>
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-6 h-6" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightCircleIcon className="w-6 h-6" />
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3 z-10">
            <div className="flex flex-col w-full max-w-lg ">
              <div className="self-center text-xl sm:text-2xl text-color-1">
                {ConfigProvider.getTranslation("Successfully Registered")}
              </div>

              <div className="mt-3 md:mt-10">
                <div className="mt-2 mb-4">
                  {ConfigProvider.getTranslation(
                    "YourEmailAddressMustBeVerifiedToLogin"
                  )}
                </div>
                <div className="flex w-full">
                  <button
                    type="button"
                    className="mt-6 flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-8 text-page-bg font-bold"
                    disabled={loading}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Home")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-6 h-6" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-6 h-6" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;
