import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import { AtSymbolIcon } from "@heroicons/react/24/solid";
import Requests from "../helpers/Requests";
import Turnstile, { useTurnstile } from "react-turnstile";

function ForgotPassword() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  const turnstile = useTurnstile();
  useEffect(() => {
    if (ConfigProvider.config.user) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    token: "",
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [messages, setMessages] = useState([]);
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/user/forgotPassword", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
          }
          setLoading(false);
          turnstile && turnstile.reset();
        })
        .catch(() => {
          setLoading(false);
          turnstile && turnstile.reset();
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3 z-10">
      <div className="flex flex-col w-full max-w-lg ">
        <div className="self-center text-xl sm:text-2xl text-color-1">
          {ConfigProvider.getTranslation("LostPassword")}
        </div>

        <div className="mt-3 md:mt-10">
          <div className="self-center text-sm text-color-3 mb-5 text-page-text border-b pb-4 font-bold">
            {ConfigProvider.getTranslation("ResetPassword.Info")}
          </div>
          <form onSubmit={updateForm}>
            <div className="mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
            <div className="flex flex-col mb-6">
              <label
                htmlFor="email"
                className="mb-1 text-xs sm:text-sm tracking-wide text-page-text font-bold"
              >
                {ConfigProvider.getTranslation("E-mail")}:
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                  <AtSymbolIcon className="w-6 h-6 text-page-text" />
                </div>

                <input
                  id="email"
                  name="email"
                  type="email"
                  className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                  placeholder={ConfigProvider.getTranslation("E-mail")}
                  value={formData.email}
                  onChange={handleChange}
                  required
                  maxLength={255}
                  disabled={loading}
                />
              </div>
            </div>
            {ConfigProvider.config.hostnames.captcha ? (
              <div className="w-full flex items-center justify-center mb-6">
                <Turnstile
                  sitekey={ConfigProvider.config.hostnames.captchaKey}
                  onVerify={(token) => {
                    setFormData((prev) => {
                      return { ...prev, token: token };
                    });
                  }}
                  theme="auto"
                />
              </div>
            ) : (
              <></>
            )}
            <div className="flex w-full">
              <button
                type="submit"
                className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-8 text-page-bg font-bold"
                disabled={loading}
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-6 h-6" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-6 h-6" />
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
