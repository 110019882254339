import { useConfig } from "../../context/ConfigContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "../../assets/css/topWinners.css";
import { Autoplay } from "swiper/modules";
import React, { useEffect, useState } from "react";
import { SvgLoader } from "react-svgmt";

function Brands() {
  const ConfigProvider = useConfig();
  const [data, setData] = useState([]);
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;
  useEffect(() => {
    let newData = [];
    ConfigProvider.config.allBrands
      .filter((x) => x.theme === "default")
      .sort((a, b) => {
        return a.order - b.order;
      })
      .forEach((element) => {
        newData.push(element);
      });
    if (newData.length > 0) {
      let i2 = 0;
      for (let index = newData.length; index <= 10; index++) {
        newData.push(newData[i2]);
        i2 += 1;
        if (i2 > newData.length) {
          i2 = 0;
        }
      }
    }
    setData(newData);
  }, [ConfigProvider.config.allBrands]);

  return data.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="mt-4 flex h-8 items-center mb-1.5 sm:mt-6">
        <div className="flex items-center text-xl font-semibold text-primary">
          <div>{ConfigProvider.getTranslation("Providers")}</div>
        </div>
      </div>
      <div className="px-4 py-2 sm:px-3">
        <Swiper
          spaceBetween={15}
          slidesPerView={"auto"}
          loop={true}
          speed={5000}
          modules={[Autoplay]}
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index} className="w-40 h-32 text-xs">
              <div className="rounded-lg w-full h-full text-center flex items-center justify-center bg-layout-bg">
                {item.type === "svg" ? (
                  <SvgLoader svgXML={item.content} className="w-32" />
                ) : (
                  <img
                    src={`${cdnEndpoint}/upload/img/${item.url}`}
                    title={item.name}
                    className="w-32"
                    alt=""
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
export default Brands;
